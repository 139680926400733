import {useAuthStore} from "~/stores/auth";

export default defineNuxtRouteMiddleware(async (to) => {
    const app = useNuxtApp()
    const store = await useAuthStore(app.$pinia)

    const user = store.user

    if (user.plan !== 'Enterprise') {
        return navigateTo({
            path: '/billing',
            query: to.query,
        })
    }
})